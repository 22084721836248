


























































































































import store from "@/store";
import {ActivityInfo} from "@/request/marketing/ActivityInfo";

import {Vue, Component} from "vue-property-decorator";
import {MemberSet} from "@/request/customer/MemberSet";

@Component({
    name: 'marketing_activityInfo'
})
export default class marketing_activityInfo extends Vue {
    pubOps: Array<any> = [];
    tableOps: Array<any> = [];
    loading: boolean = false;
    delLoading: boolean = false;
    showDrawer: boolean = false;
    formData: any = {
        name: '',
        calcUnit: 0,
        lv: 0,
        rules: [
            {
                salesRules: [
                    {}
                ]
            }
        ]
    };
    formRules: any = {
        name: {required: true, trigger: 'blur'}
    };
    params: any = {
        pageSize: 3
    };
    list: any = {};
    lvs: any = [];
    created() {
        store.dispatch('getOperates').then((ops: any) => {
            this.pubOps = ops.pubOps;
            this.tableOps = ops.tableOps;
        })
        this.getData();
    };
    getData() {
        let self: any = this;
        const msg = self.$Message.loading({
            content: '加载中...',
            duration: 0
        });
        Promise.all([
            ActivityInfo.list(this.params),
            MemberSet.getAll()
        ]).then((res: Array<any>) => {
            setTimeout(msg, 500);
            self.list = res[0].data;
            self.lvs = res[1].data;

            self.list.list.forEach((j: any) => {
                if (j.calcUnit === 0) { //单位为人民币需要转换
                    j.rules.forEach((k: any) => {
                        console.log(k);
                        k.salesRules.forEach((l: any) => {
                            l.num = l.num / 100
                        })
                    })
                }
            })
        })
    };
    pageChange(pageIndex: number) {
        this.params.pageIndex = pageIndex;
        this.getData();
    }
    addRules() {
        this.formData.rules.push({
            salesRules: [
                {
                    discountRate: 1,
                    num: 1
                }
            ]
        });
    }
    addSalesRulesItem(item: any) {
        item.salesRules.push({});
    };
    createNew() {
        let self: any = this;
        delete self.formData.id;
        self.showDrawer = true;
        self.$refs['el-form'].resetFields();
        self.formData.rules = [{salesRules: [{}]}]
    };
    edit(row: any) {
        let self: any = this;
        this.showDrawer = true;
        this.formData = self.assignObject(row);
    }
    del(id: string) {
        this.delLoading = true;
        ActivityInfo.del([id]).then((body: any) => {
            this.delLoading = false;
            if (body.code === 0) {
                this.getData();
            }
        })
    }
    submit() {
        let self: any = this;
        self.$refs['el-form'].validate((val: boolean) => {
            if (val) {
                this.loading = true;
                let params = self.assignObject(this.formData);
                if (params.calcUnit === 0) { //单位为人民币
                    params.rules.forEach((j: any) => {
                        j.salesRules.forEach((k: any) => {
                            k.num = k.num * 100;
                        })
                    })
                }
                ActivityInfo.save(params).then((body: any) => {
                    this.loading = false;
                    if (body.code === 0) {
                        this.showDrawer = false;
                        this.getData();
                    }
                })
            }
        })
    }
}

